<script>
import { h } from 'vue';
import moment from 'moment';

export default {
  props: {
    fieldName: String,
    fieldVal: null,
    id: String,
    additionalData: null,
  },

  render() {
    const { currentProduct, currentTab, currentSubTab } = this.$parent.appStore;

    let fieldName = this.fieldName;
    let fieldVal = this.fieldVal;
    let id = this.id;
    let additionalData = this.additionalData;


    if (currentProduct === 'qvCreditPersonalLoan' && currentTab === 'acknowledged') {
      if (fieldName === 'personalNumber' && fieldVal) {
        // const background = `#${parent.intToRGB(parent.hashCode(fieldVal))}`;
        // const color = parent.pickTextColorBasedOnBgColorAdvanced(background);
        const cursor = 'pointer';
        const styleAttr = {
          style: { cursor },
          // class: 'personal-id-tag',
        }
        return h('span', styleAttr, fieldVal);
      }

      if (fieldName === 'address' && typeof fieldVal === 'object') {
        const addressArray = [];
        let addressFields = [];
        let address = '';

        addressFields = ['addressLine1', 'addressLine2', 'district'];
        addressFields.forEach((field) => {
          if (fieldVal[field]) {
            const value = (field === 'district' ? this.$parent.resolveDomain(fieldVal[field], field) : fieldVal[field]);
            address = (address === '' ? value : `${address}, ${value}`);
          }
        })
        addressArray.push(h(
          'div',
          { class: 'addressLine' },
          [h('p', {}, address)]
        ));

        return h('div', [addressArray]);
      }

      if (fieldName === 'uploads') {
        if (fieldVal) {
          return h(
            'button',
            {
              class: 'light-grey-button sharp-corner-button',
              onclick: () => this.$parent.showUploadedFiles(id),
            },
            this.$parent.getButtonLabel('lendelaDriveBtn'),
          )
        }
        return h('div', {}, 'N/A');
      }

      if (fieldName === 'offer') {
        const offerArray = [];

        this.$parent.list.forEach((field) => {
          const elLeft = h('div', { style: 'float: left' }, this.$parent.fieldLabel(field));
          const elRight = h('div', { style: 'float: right' }, this.$parent.fieldData(field, fieldVal[field]));
          const br = h('br');
          const el = offerArray.push(h('div', { class: 'given-offer-details' }, [elLeft, elRight, br]));
        });

        return h('div', [offerArray]);
      }

      if (fieldName === 'cpfContributions') {
        if (fieldVal) {
          return h(
            'button',
            { 
              class: 'light-grey-button sharp-corner-button', 
              onclick: () => this.$parent.showCpfModal(id, fieldVal), 
            },
            'View CPF'
          );
        };
        return h('div', {}, 'N/A');
      }

      if (fieldName === 'broadcastsToLender') {
        if (!fieldVal || !fieldVal.length) return h('div', {}, 'N/A');

        const messages = [];
        fieldVal.forEach((broadcast, i) => {
          const createEl = [h(
            'div', {}, broadcast.message
          ), h(
            'div',
            {
              style: 'font-size: 12px; cursor: pointer',
            },
            moment(broadcast.timestamp).format('MMM DD YYYY h:mm a')
          )];
          messages.push(createEl);
        });

        return h(
          'div',
          [messages],
        );
      }

      if (fieldName === 'myInfoData') {
        if (fieldVal === 'myinfo' && additionalData.showHasAdditionalMyinfoData) {
          return h(
            'button',
            { 
              class: 'light-grey-button sharp-corner-button', 
              onclick: () => this.$parent.showMyinfoModal(id),
            },
            this.$parent.getButtonLabel('myInfoDataBtn'),
          );
        }
        return h('div', {}, 'N/A');
      }

      if (fieldName === 'moneylenderDebtToIncomeRatio' && fieldVal !== null && fieldVal !== undefined) {
        fieldVal = fieldVal.toString().slice(0, 4);
      }

      const fieldType = this.$parent.fieldType(fieldName);
      return h('div', this.$parent.formatField(fieldType, fieldVal, fieldName));
    }

    if (fieldName === 'personalNumber' && fieldVal) {
      // const background = `#${parent.intToRGB(parent.hashCode(fieldVal))}`;
      // const color = parent.pickTextColorBasedOnBgColorAdvanced(background);
      const cursor = 'pointer';
      const styleAttr = {
        // class: 'personal-id-tag',
        style: { cursor },
        'data-content': fieldVal,
        ondblclick: async () => {
          await navigator.clipboard.writeText(fieldVal);
          const alt = document.createElement('div');
          alt.setAttribute('class', 'copy-popup');
          alt.innerHTML = this.$parent.tableHeaderLabel('copiedText');
          setTimeout(function () {
            alt.parentNode.removeChild(alt);
          }, 1500);
          document.body.appendChild(alt);
        }
      }
      return h('span', styleAttr);
    }

    if (fieldName === 'language') {
      let langLabel = ''
      if ((fieldVal.locale[0] === 'en' && (fieldVal.speakChinese.length === 0 || fieldVal.speakChinese[0] === false))) {
        langLabel = 'english'
      } else if ((fieldVal.locale[0] === 'en' && fieldVal.speakChinese[0] === true) || (fieldVal.locale[0] === 'hk')) {
        langLabel = 'chinese'
      }
      return h('div', {
        style: 'user-select: none; cursor: pointer',
        onDblclick: async () => {
          await navigator.clipboard.writeText(this.$parent.getLanguageLabel(langLabel));
          const alt = document.createElement('div');
          alt.setAttribute('class', 'copy-popup');
          alt.innerHTML = this.$parent.tableHeaderLabel('copiedText');
          setTimeout(function () {
            alt.parentNode.removeChild(alt);
          }, 1500);
          document.body.appendChild(alt);
        }
      },
      this.$parent.getLanguageLabel(langLabel))
    }

    if (fieldName === 'debtInfo') {
      if (fieldVal.length > 0) {
        return h(
          'button',
          { 
            class: 'light-grey-button sharp-corner-button', 
            onclick: () => this.$parent.showDebtInfoModal(id, fieldVal),
          },
          this.$parent.getButtonLabel('viewDebtBtn')
        );
      }
      return h('div', {}, 'N/A');
    }

    const customAddressFormat = (
      fieldName === 'address' &&
      ['easyLendPersonalLoan', 'financeOnePersonalLoan'].includes(currentProduct) &&
      (['chosen', 'acknowledged'].includes(currentTab) || ['offerRevisionChosen', 'offerRevisionAcknowledged'].includes(currentSubTab))
    );
    if (customAddressFormat) {
      const addressParams = [];
      const addressArray = [];

      if (additionalData.personalAddress) {
        addressParams.push(additionalData.personalAddress);
      }

      if (additionalData.address && additionalData.address.district) {
        addressParams.push(this.$parent.resolveDomain(additionalData.address.district, 'district'));
      }

      const address = addressParams.join(', ');
      addressArray.push(h(
        'div',
        { class: 'addressLine' },
        [h(
          'p',
          {
            style: 'cursor: pointer',
            'data-content': address,
            ondblclick: async () => {
              await navigator.clipboard.writeText(address);
              const alt = document.createElement('div');
              alt.setAttribute('class', 'copy-popup');
              alt.innerHTML = this.$parent.tableHeaderLabel('copiedText');
              setTimeout(function () {
                alt.parentNode.removeChild(alt);
              }, 1500);
              document.body.appendChild(alt);
            }
          }
        )]
      ))
      return h('div', [addressArray]);
    }

    const customPokoCreditPLoanAddressFormat = (
      fieldName === 'address' && (currentProduct === 'pokoOfflinePersonalLoan' || currentProduct === 'creditStationPersonalLoan' || currentProduct === 'konewFinancialExpressPersonalLoan')
    );

    if (customPokoCreditPLoanAddressFormat) {
      const addressArray = [];
      let addressFields = [];
      let address = '';

      addressFields = ['addressLine', 'addressLine1', 'addressLine2', 'district'];
      addressFields.forEach((field) => {
        if (fieldVal[field]) {
          const value = (field === 'district' ? this.$parent.resolveDomain(fieldVal[field], field) : fieldVal[field]);
          address = (address === '' ? value : `${address}, ${value}`);
        }
      })

      if (additionalData.residentialStatus === 'self-owned' || additionalData.residentialStatus === 'jointly-owned') {
        addressArray.push(h(
          'div',
          { class: 'pokoCreditAddressLine' },
          [h(
            'p',
            {
              style: 'color: red; cursor: pointer',
              'data-content': address,
              ondblclick: async () => {
                await navigator.clipboard.writeText(address);
                const alt = document.createElement('div');
                alt.setAttribute('class', 'copy-popup');
                alt.innerHTML = this.$parent.tableHeaderLabel('copiedText');
                setTimeout(function () {
                  alt.parentNode.removeChild(alt);
                }, 1500);
                document.body.appendChild(alt);
              }
            }
          )]
        ));
      } else {
        addressArray.push(h(
          'div',
          { class: 'pokoCreditAddressLine' },
          [h(
            'p',
            {
              style: 'cursor: pointer',
              'data-content': address,
              ondblclick: async () => {
                await navigator.clipboard.writeText(address);
                const alt = document.createElement('div');
                alt.setAttribute('class', 'copy-popup');
                alt.innerHTML = this.$parent.tableHeaderLabel('copiedText');
                setTimeout(function () {
                  alt.parentNode.removeChild(alt);
                }, 1500);
                document.body.appendChild(alt);
              }
            }
          )]
        ));
      }

      return h('div', [addressArray]);
    }

    if (fieldName === 'address' && typeof fieldVal === 'object') {
      const addressArray = [];
      let addressFields = [];
      let address = '';

      addressFields = ['addressLine1', 'addressLine2', 'district'];
      addressFields.forEach((field) => {
        if (fieldVal[field]) {
          const value = (field === 'district' ? this.$parent.resolveDomain(fieldVal[field], field) : fieldVal[field]);
          address = (address === '' ? value : `${address}, ${value}`);
        }
      })
      addressArray.push(h(
        'div',
        { class: 'addressLine' },
        [h(
          'p',
          {
            style: 'cursor: pointer',
            'data-content': address,
            ondblclick: async () => {
              await navigator.clipboard.writeText(address);
              const alt = document.createElement('div');
              alt.setAttribute('class', 'copy-popup');
              alt.innerHTML = this.$parent.tableHeaderLabel('copiedText');
              setTimeout(function () {
                alt.parentNode.removeChild(alt);
              }, 1500);
              document.body.appendChild(alt);
            }
          }
        )]
      ));

      return h('div', [addressArray]);
    }

    if (fieldName === 'uploads') {
      if (fieldVal) {
        return h(
          'button',
          {
            class: 'light-grey-button sharp-corner-button',
            onclick: () => {
              this.$parent.showUploadedFiles(id)
            }
          },
          this.$parent.getButtonLabel('lendelaDriveBtn'),
        )
      }
      return h('div', {}, 'N/A');
    }

    if (fieldName === 'offer') {
      const offerArray = [];

      this.$parent.list.forEach((field) => {
        const elLeft = h('div', { 
          style: 'float: left',
          'data-content': this.$parent.fieldLabel(field),
        });
        const elRight = h('div', { 
          style: 'float: right', 
          'data-content': this.$parent.fieldData(field, fieldVal[field]),
        });
        const br = h('br');
        const el = offerArray.push(h('div', { 
          class: 'given-offer-details' 
        }, [elLeft, elRight, br]));
      });

      return h('div', [offerArray]);
    }

    if (fieldName === 'cpfContributions') {
      if (fieldVal === 'myinfo') {
        return h(
          'button',
          { 
            class: 'light-grey-button sharp-corner-button', 
            onclick: () => this.$parent.showMyinfoModal(id),
          },
          'View My info'
        );
      };
      return h('div', {}, 'N/A');
    }

    if (fieldName === 'myInfoData') {
      if (fieldVal === 'myinfo' && additionalData.showHasAdditionalMyinfoData) {
        return h(
          'button',
          { 
            class: 'light-grey-button sharp-corner-button', 
            onclick: () => this.$parent.showMyinfoModal(id),
          },
          this.$parent.getButtonLabel('myInfoDataBtn'),
        );
      }
      return h('div', {}, 'N/A');
    }

    if (fieldName === 'broadcastsToLender') {
      if (!fieldVal || !fieldVal.length) return h('div', { 
        'data-content': 'N/A',
      });

      const messages = [];
      fieldVal.forEach((broadcast, i) => {
        const createEl = [
          h(
            'div',
            {
              class: 'brodcast-text',
              'data-content': broadcast.message,
              style: (currentTab === 'lead') ? 'color:red' : '',
              onDblclick: async () => {
                await navigator.clipboard.writeText(broadcast.message + ' - ' + (this.$parent.nuxtStore.language === 'EN' ? moment(broadcast.timestamp).format('MMM DD, YYYY h:mm a') : moment(broadcast.timestamp).format('YYYYå¹´MæœˆDæ—¥ h:mmA')));
                const alt = document.createElement('div');
                alt.setAttribute('class', 'copy-popup');
                alt.innerHTML = this.$parent.tableHeaderLabel('copiedText');
                setTimeout(function () {
                  alt.parentNode.removeChild(alt);
                }, 1500);
                document.body.appendChild(alt);
              }
            },
          ), 
          h(
            'div',
            {
              class: 'brodcast-date',
              'data-content': (this.$parent.nuxtStore.language === 'EN' ? moment(broadcast.timestamp).format('MMM DD, YYYY h:mm a') : moment(broadcast.timestamp).format('YYYYå¹´MæœˆDæ—¥ h:mmA')),
              onDblclick: async () => {
                await navigator.clipboard.writeText(broadcast.message + ' - ' + (this.$parent.nuxtStore.language === 'EN' ? moment(broadcast.timestamp).format('MMM DD, YYYY h:mm a') : moment(broadcast.timestamp).format('YYYYå¹´MæœˆDæ—¥ h:mmA')));
                const alt = document.createElement('div');
                alt.setAttribute('class', 'copy-popup');
                alt.innerHTML = this.$parent.tableHeaderLabel('copiedText');
                setTimeout(function () {
                  alt.parentNode.removeChild(alt);
                }, 1500);
                document.body.appendChild(alt);
              }
            },
          )
        ];
        messages.push(createEl);
      });

      return h(
        'div',
        [messages],
      );
    }

    if (fieldName === 'moneylenderDebtToIncomeRatio' && fieldVal !== null && fieldVal !== undefined) {
      fieldVal = fieldVal.toString().slice(0, 4);
    }

    if (fieldName === 'birthYear' && fieldVal) {
      // console.log(fieldName, fieldVal, additionalData)
      let birthYear = `${fieldVal}`;
      if (additionalData.birthMonth) {
        birthYear = `${additionalData.birthMonth} ${birthYear}`;
      }

      return h(
        'div', 
        {
          style: { cursor: 'pointer' },
          'data-content': birthYear,
          onDblclick: async () => {
            await navigator.clipboard.writeText(birthYear);
            const alt = document.createElement('div');
            alt.setAttribute('class', 'copy-popup');
            alt.innerHTML = this.$parent.tableHeaderLabel('copiedText');
            
            document.body.appendChild(alt);
            
            setTimeout(() => {
              alt.parentNode?.removeChild(alt);
            }, 1500);
          }
        }
      );
    }

    const fieldType = this.$parent.fieldType(fieldName);
    return h(
      // 'div', { style: 'cursor: pointer', attrs: { 'data-content': parent.formatField(fieldType, fieldVal, fieldName) }, on: { async dblclick () { if (fieldType === 'date-time') { await navigator.clipboard.writeText((parent.nuxtStore.language === 'EN') ? moment(fieldVal).format('MMMM DD, YYYY h:mm A') : moment(fieldVal).format('YYYY年M月D日 h:mmA')) } else { await navigator.clipboard.writeText(parent.formatField(fieldType, fieldVal, fieldName)) }; const alt = document.createElement('div'); alt.setAttribute('class', 'copy-popup'); alt.innerHTML = parent.tableHeaderLabel('copiedText'); setTimeout(function () { alt.parentNode.removeChild(alt); }, 1500); document.body.appendChild(alt) } } });
      'div', {
        style: 'cursor: pointer',
        'data-content': this.$parent.formatField(fieldType, fieldVal, fieldName),
        onDblclick: async () => {
          if (fieldType === 'date-time') {
            await navigator.clipboard.writeText((this.$parent.nuxtStore.language === 'EN') ? moment(fieldVal).format('MMMM DD, YYYY h:mm A') : moment(fieldVal).format('YYYY年M月D日 h:mmA'));
          } else {
            await navigator.clipboard.writeText(this.$parent.formatField(fieldType, fieldVal, fieldName));
          }
          const alt = document.createElement('div');
          alt.setAttribute('class', 'copy-popup');
          alt.innerHTML = this.$parent.tableHeaderLabel('copiedText');
          setTimeout(function () {
            alt.parentNode.removeChild(alt);
          }, 1500);
          document.body.appendChild(alt);
        }
      }
    );
  },
};
</script>
